/* Inputs */

input {
  all: unset;
  display: block;
}

input:not([type="checkbox"]) {
  all: unset;
  display: block;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  padding: 14px 16px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #b3b6b8;
  color: #585a5c;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
}

input:not([type="checkbox"]):focus {
  border-radius: 8px;
  border: 1px solid #03bfbf;
  box-shadow: 0px 0px 0px 3px rgba(3, 191, 191, 0.15);
}

/* Para inputs con estado inválido */
.input-invalid:not([type="checkbox"]) {
  border-radius: 8px;
  border: 1px solid #d31212 !important;
  box-shadow: 0px 0px 0px 3px rgba(220, 53, 69, 0.2) !important;
}


input:focus {
  border-radius: 8px;
  border: 1px solid #03bfbf;
  box-shadow: 0px 0px 0px 3px rgba(3, 191, 191, 0.15);
}

.container-input {
  position: relative;
  width: 100%;
}

.label {
  position: absolute;
  top: -0px;
  border-radius: 5px;
  padding: 0 3px;
  left: 12px;
  transform: translateY(-50%);
  border-radius: 2px;
  background: #f4f5f5;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;

  color: #232324;
  font-family: "Nunito Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
}

input:not([type="checkbox"]):focus ~ label {
  background: #03bfbf;
  color: #fff;
  font-family: "Nunito Sans";
  font-size: 12px !important;
  font-style: normal;
  line-height: 120%;
}

.label-error {
  position: absolute;
  top: -0px;
  border-radius: 5px;
  padding: 0 3px;
  left: 12px;
  transform: translateY(-50%);
  border-radius: 2px;
  background: #d31212 !important;
  color: #ffffff;
  font-size: 12px;
  font-family: "Nunito Sans";
}

.input-invalid {
  border-radius: 8px;
  border: 1px solid #d31212 !important;
  box-shadow: 0px 0px 0px 3px rgba(220, 53, 69, 0.2) !important;
}
