/* You can add global styles to this file, and also import other style files */
@import "../public/styles/custom-bootstrap.scss";
@import "../public/styles/stonestore-styles.scss";

@font-face {
  font-family: "Costa Std";
  src: url('../public/font/costa-std/fonnts.com-Costa_Std.otf') format('woff2');
}


html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Nunito Sans";
}
