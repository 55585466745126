// Import Bootstrap's source Sass files
@import "bootstrap/scss/bootstrap";

.btn-filled-button {
  border-radius: 12px;
  background-color: #ff9f00;
  display: inline-flex;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;

  color: #fff;
  text-align: center;

  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 136%;

  &:hover {
    background-color: darken(#ff9f00, 10%);
    color: #fff;
  }

  &:focus {
    background-color: lighten(#ff9f00, 10%);
    color: #fff;
  }

  &:active {
    border-radius: 12px;
    background-color: #ff9f00 !important;
    border: #ff9f00;
    color: #fff !important;
  }

  &:disabled {
    background-color: #ffe3b6;
    border: #ffe3b6;
    color: #6b4300;
  }
}

.btn-text-button {
  display: inline-flex;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 12px;

  color: #03bfbf;
  text-align: center;

  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 136%;

  &:hover {
    background-color: rgba(101, 85, 143, 0.08) !important;
    color: #03bfbf;
  }

  &:focus {
    background-color: rgba(101, 85, 143, 0.08) !important;
    color: #03bfbf;
  }

  &:active {
    border: rgba(101, 85, 143, 0.08) !important;
    background-color: rgba(101, 85, 143, 0.08) !important;
    color: #03bfbf !important;
  }

  &:disabled {
    color: #03bfbf;
    opacity: 0.38;
    border: none;
  }
}

.card {
  border-radius: 20px !important;
  background: var(--Surface-Container-High, #f4f5f5) !important;
}


